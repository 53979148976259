
import app from '@/store/modules/app'
import selfServe from '@/store/modules/selfServe'
import { Vue, Component } from 'vue-property-decorator'
import WizardProgressTracker from '@/components/WizardProgressTracker.vue'
import CUIcon from '@/components/CUIcon.vue'
import Chat from '@/components/Chat.vue'
import { SelfServeStep } from '@/models/SelfServeStep'
import selfServeService from '@/services/selfServe'

@Component({
  metaInfo() {
    return {
      title: app.title,
      titleTemplate: `${this.$t('selfServe.TITLE')} - %s`,
    }
  },
  components: {
    WizardProgressTracker,
    CUIcon,
    Chat
  },
})
export default class SelfServe extends Vue {
  selfServe = selfServe

  get currentStep(): SelfServeStep {
    return selfServe.step
  }

  get component(): any {
    return this.currentStep?.component
  }

  get header(): string {
    return this.currentStep?.header ?? ''
  }

  get subhead(): string {
    return this.currentStep?.subhead ?? ''
  }

  get showBackButton(): boolean {
    return (
      this.currentStep &&
      this.currentStep.orderIndex > 0 &&
      !this.currentStep.preventBackwardsNavigation
    )
  }

  get showChat(): boolean {
    return this.currentStep?.orderIndex > 0
  }

  async created(): Promise<void> {
    const { quoteId } = this.$route?.params

    if (!!quoteId) {
      await this.handleExistingQuoteEdit(parseInt(quoteId))
    } else {
      selfServe.initialize()
    }
  }

  //handle case where we have quoteId as route param
  async handleExistingQuoteEdit(quoteId: number): Promise<void> {
    try {
      const result = await selfServeService.get(quoteId)
      const quote = result.data?.data
      selfServe.initialize(quote)
      selfServe.setIsExistingUser(true)
      selfServe.setIsEditing(true)
      selfServe.setQuoteId(quoteId)
      selfServe.setSelectedQuoteId(quoteId)
      selfServe.moveToItineraryStep()
    } catch (error) {
      this.$router.push({ name: 'quote-index' })
    }
  }
}
